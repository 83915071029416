import axios from 'auth/FetchInterceptor';

const CustomerSupportService = {};

CustomerSupportService.addCustomerSupport = async (data) => {
    const response = await axios({
        url: '/customer-support-ticket',
        method: 'post',
        data,
    });

    return response;
};
CustomerSupportService.getCustomerSupportTicket = async () => {
    const response = await axios({
        url: '/get-customer-support-ticket',
        method: 'get',
    });

    return response;
};

export default CustomerSupportService;
