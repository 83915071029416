import React, { lazy, Suspense, memo } from 'react';
import { useSelector } from 'react-redux';
import Loading from 'components/shared-components/Loading';
import Views from 'views';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
    const token = useSelector((state) => state.auth.token);
    const blankLayout = useSelector((state) => state.theme.blankLayout);
    const address = useSelector(
        (state) => state?.auth?.loginOrRegister?.loginResponse?.data?.[0]?.owner_address,
    );
    const status = useSelector(
        (state) => state?.auth?.loginOrRegister?.loginResponse?.data?.[0]?.status,
    );

    const Layout = token && !blankLayout && address && status === 2 ? AppLayout : AuthLayout;

    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Layout>
                <Views />
            </Layout>
        </Suspense>
    );
};

export default memo(Layouts);
