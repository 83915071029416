import axios from 'auth/FetchInterceptor';

const BankService = {};

BankService.getBankDetails = async () => {
    const response = await axios({
        url: '/get-kitchen-owner-bank-detail',
        method: 'get',
    });
    return response;
};
BankService.getBankList = async () => {
    const response = await axios({
        url: '/get-banks',
        method: 'get',
    });
    return response;
};

BankService.addBankDetail = async (data) => {
    const response = await axios({
        url: '/add-or-update-kitchen-owner-bank-details',
        method: 'post',
        data,
    });
    return response;
};

BankService.updatePrimaryTransaction = async (data) => {
    const response = await axios({
        url: '/update-transaction-type',
        method: 'patch',
        data,
    });
    return response;
};

export default BankService;
