// const dev = {
//     API_ENDPOINT_URL: 'https://dev-api.tastesonway.com/api/v2', //https://dev-api.tastesonway.com/api/v2
// };

// const prod = {
//     API_ENDPOINT_URL: 'https://api.tastesonway.com/api/v2', //http://192.168.1.26:24/api/v2/
// };

// const test = {
//     API_ENDPOINT_URL: 'http://192.168.1.26:24/api/v2/',
// };

// const getEnv = () => {
//     switch (
//         'development' // Ensure that you are using apropriate switch before publish
//     ) {
//         case 'development':
//             return dev;
//         case 'production':
//             return prod;
//         case 'test':
//             return test;
//         default:
//             break;
//     }
// };

console.log("🚀 ~ file: EnvironmentConfig.js:28 ~ process.env.REACT_APP_API_URL:", process.env,process.env.REACT_APP_API_URL)

export const env = {
    API_ENDPOINT_URL: process.env.REACT_APP_API_URL
};
