import {
    auth,
    signInWithEmailAndPassword,
    signOut,
    googleAuthProvider,
    facebookAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithPhoneNumber,
} from 'auth/FirebaseAuth';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';

const FirebaseService = {};

FirebaseService.signInEmailRequest = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password)
        .then((user) => user)
        .catch((err) => err);
};
FirebaseService.signInWithPhone = async (phoneNumber) => {
    const configureCaptcha = () => {
        const auth = getAuth();
        window.recaptchaVerifier = new RecaptchaVerifier(
            'sign-in-button',
            {
                size: 'invisible',
                callback: (response) => {
                    return response;
                },
                defaultCountry: 'IN',
            },
            auth,
        );
    };
    configureCaptcha();
    const appVerifier = window.recaptchaVerifier;
    return await signInWithPhoneNumber(getAuth(), phoneNumber, appVerifier)
        .then((confirmationResult) => {
            return (window.confirmationResult = confirmationResult);
        })
        .catch((err) => err);
};

FirebaseService.signOutRequest = async () =>
    await signOut(auth)
        .then((user) => user)
        .catch((err) => err);

FirebaseService.signInGoogleRequest = async () =>
    await signInWithPopup(auth, googleAuthProvider)
        .then((user) => user)
        .catch((err) => err);

FirebaseService.signInFacebookRequest = async () =>
    await signInWithPopup(auth, facebookAuthProvider)
        .then((user) => user)
        .catch((err) => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
    await createUserWithEmailAndPassword(auth, email, password)
        .then((user) => user)
        .catch((err) => err);

export default FirebaseService;
