import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BankService from 'services/BankService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    getBankDetails: '',
    bankList: '',
    addBankDetails: '',
    updateTransaction: '',
};

export const getBankDetails = createAsyncThunk(
    'get-kitchen-owner-bank-detail',
    async (_, { rejectWithValue }) => {
        try {
            const response = await BankService.getBankDetails();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const getBankList = createAsyncThunk('get-banks', async (_, { rejectWithValue }) => {
    try {
        const response = await BankService.getBankList();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const addBankDetail = createAsyncThunk(
    'add-or-update-kitchen-owner-bank-details',
    async (data, { rejectWithValue }) => {
        try {
            const response = await BankService.addBankDetail(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const updatePrimaryTransaction = createAsyncThunk(
    'update-transaction-type',
    async (data, { rejectWithValue }) => {
        try {
            const response = await BankService.updatePrimaryTransaction(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const removeMessageBank = createAsyncThunk(
    'removeMessageBank',
    async (_, { rejectWithValue }) => {
        try {
            const data = null;
            return data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const bankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = '/';
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = '';
            state.showMessage = false;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        getBankDetailsSuccess: (state, action) => {
            state.loading = false;
            state.getBankDetails = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBankDetails.pending, (state) => {
                state.getBankDetails = '';
            })
            .addCase(getBankDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.getBankDetails = action.payload;
            })
            .addCase(getBankDetails.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getBankList.pending, (state) => {
                state.bankList = '';
            })
            .addCase(getBankList.fulfilled, (state, action) => {
                state.loading = false;
                state.bankList = action.payload;
            })
            .addCase(getBankList.rejected, (state) => {
                state.loading = false;
            })
            .addCase(addBankDetail.pending, (state) => {
                state.addBankDetails = '';
            })
            .addCase(addBankDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.addBankDetails = action.payload;
            })
            .addCase(addBankDetail.rejected, (state) => {
                state.loading = false;
            })
            .addCase(updatePrimaryTransaction.pending, (state) => {
                state.addBankDetails = '';
            })
            .addCase(updatePrimaryTransaction.fulfilled, (state, action) => {
                state.loading = false;
                state.updateTransaction = action.payload;
            })
            .addCase(updatePrimaryTransaction.rejected, (state) => {
                state.loading = false;
            })
            .addCase(removeMessageBank.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeMessageBank.fulfilled, (state, action) => {
                state.loading = false;
                state.addBankDetails = action.payload;
            })
            .addCase(removeMessageBank.rejected, (state, action) => {
                state.loading = false;
                state.addBankDetails = action.payload;
            });
    },
});

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    showLoading,
    getBankDetailsSuccess,
} = bankSlice.actions;

export default bankSlice.reducer;
