import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import bank from './slices/bankSlice';
import menu from './slices/menuSlice';
import user from './slices/userSlice';
import review from './slices/reviewSlice';
import customerSupport from './slices/customerSupportSlice';
import order from './slices/orderSlice';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        bank,
        menu,
        user,
        customerSupport,
        order,
        review,
        ...asyncReducers,
    });
    return combinedReducer(state, action);
};

export default rootReducer;
