import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    countryData: '',
    loginOrRegister: JSON.parse(localStorage.getItem('loginOrRegister')) || '',
    userProfile: null,
    token: localStorage.getItem(AUTH_TOKEN) || null,
};
export const otpSend = createAsyncThunk(
    'kitchen-owner-login-registration',
    async (data, { rejectWithValue }) => {
        const countryCode = data?.countryCode;
        const code = countryCode.split('-');
        const phoneNumber = `+${code[0]}${data?.phone}`;
        console.log('🚀 ~ file: authSlice.js:54 ~ data', phoneNumber);
        try {
            const firebaseResponse = await FirebaseService.signInWithPhone(phoneNumber);
            console.log('🚀 ~ file: authSlice.js:50 ~ firebaseResponse', firebaseResponse);
            // localStorage.setItem('firebaseResponse', JSON.stringify(firebaseResponse));
            return firebaseResponse;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const signIn = createAsyncThunk(
    'kitchen-owner-login-registration',
    async (data, { rejectWithValue }) => {
        const countryCode = data?.countryCode;
        const code = countryCode.split('-');
        const apiData = {
            language_id: '1',
            mobile_number: data?.phone,
            short_code: code?.[1],
            country_code: code?.[0],
        };
        try {
            const loginResponse = await AuthService.login(apiData);
            const response = { loginResponse };
            // localStorage.setItem('loginResponse', JSON.stringify(loginResponse));
            localStorage.setItem('loginOrRegister', JSON.stringify(response));
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const redirectToDashboard = createAsyncThunk(
    'redirectToDashboard',
    async (data, { rejectWithValue }) => {
        try {
            const token = data;
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const countryCode = createAsyncThunk('get-country', async (_, { rejectWithValue }) => {
    try {
        const response = await AuthService.countryCode();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const getUserProfile = createAsyncThunk(
    'get-User-Profile',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthService.getUserProfile();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const signOut = createAsyncThunk('auth/logout', async () => {
    localStorage.removeItem(AUTH_TOKEN);
    return null;
});

export const signInWithGoogle = createAsyncThunk(
    'auth/signInWithGoogle',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthService.loginInOAuth();
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const signInWithFacebook = createAsyncThunk(
    'auth/signInWithFacebook',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthService.loginInOAuth();
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = '/';
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = '';
            state.showMessage = false;
        },
        signOutSuccess: (state) => {
            state.loading = false;
            state.token = null;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        signInSuccess: (state, action) => {
            state.loading = false;
            state.token = action.payload?.data?.[0]?.token;
            state.otpSend = true;
        },
        otpSendSuccess: (state, action) => {
            state.loading = false;
            state.action = action.payload;
            state.otpSend = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loginOrRegister = action.payload;
                state.loading = false;
                state.otpSend = true;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(countryCode.pending, (state) => {
                state.countryData = '';
            })
            .addCase(countryCode.fulfilled, (state, action) => {
                state.loading = false;
                state.countryData = action.payload;
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = '';
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = '';
            })
            .addCase(redirectToDashboard.pending, (state) => {
                state.loading = true;
            })
            .addCase(redirectToDashboard.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload;
            })
            .addCase(redirectToDashboard.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signInWithGoogle.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInWithGoogle.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = '/';
                state.token = action.payload;
            })
            .addCase(signInWithGoogle.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signInWithFacebook.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInWithFacebook.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = '/';
                state.token = action.payload;
            })
            .addCase(signInWithFacebook.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getUserProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.userProfile = action?.payload?.data;
            })
            .addCase(getUserProfile.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
                state.UserProfile = {};
            });
    },
});

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    signInSuccess,
    otpSendSuccess,
} = authSlice.actions;

export default authSlice.reducer;
