import axios from 'auth/FetchInterceptor';

const OrderSerivce = {};

OrderSerivce.getOrderHstory = async (data, pageNumber) => {
    const response = await axios({
        url: `/order-history?page=${pageNumber}`,
        method: 'post',
        data,
    });

    return response;
};
OrderSerivce.getOrderList = async (data, page) => {
    const response = await axios({
        url: `/kitchen-owner-order-list?page=${page}`,
        method: 'post',
        data,
    });

    return response;
};
OrderSerivce.updateOrderStatus = async (data) => {
    const response = await axios({
        url: '/update-order-status',
        method: 'post',
        data,
    });

    return response;
};
OrderSerivce.updateDeliveryPersonStatus = async (data) => {
    const response = await axios({
        url: '/delivery-person-status-update',
        method: 'post',
        data,
    });

    return response;
};

export default OrderSerivce;
