import axios from "auth/FetchInterceptor"

const ReviewService ={}

ReviewService.reviewHistory = async(pageNumber)=>{
    const response = await axios({
        url: `/get-kitchen-owner-review-history?page=${pageNumber}`,
        method: 'get',
    })
    return response
}
export default ReviewService