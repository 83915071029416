import axios from 'auth/FetchInterceptor';
import AxiosInstance from 'axios';
const MenuService = {};

MenuService.createMenu = async (data) => {
    const response = await axios({
        url: '/create-or-update-menu',
        method: 'post',
        data,
    });
    return response;
};

MenuService.getMenuList = async (data) => {
    const response = await axios({
        url: `get-owner-menu?page=${data?.page ? data.page : 1}`,
        method: 'post',
        data,
    });
    return response;
};

MenuService.getAllMenuItemList = async (data) => {
    const response = await axios({
        url: `get-menu-item`,
        method: 'post',
        data,
    });
    return response;
};

MenuService.getOwnerMenuByID = async (data) => {
    const response = await axios({
        url: `get-owner-menu-by-id`,
        method: 'post',
        data,
    });
    return response;
};


MenuService.deleteIngredientItem = async (data) => {
    const response = await axios({
        url: `delete-item-ingredient`,
        method: 'delete',
        data,
    });
    return response;
};

MenuService.editMenu = async (data) => {
    const editMenuRes = await axios({
        url: '/create-or-update-menu',
        method: 'post',
        data,
    });
    return editMenuRes;
};

MenuService.deleteMenu = async (data) => {
    const response = await axios({
        url: `delete-menu`,
        method: 'post',
        data,
    });
    return response;
};

MenuService.deleteMenuItem = async (data) => {
    const response = await axios({
        url: `delete-menu-item`,
        method: 'post',
        data,
    });
    return response;
};

MenuService.getMenuById = async (data) => {
    const response = await axios({
        url: `get-menu-item`,
        method: 'post',
        data,
    });
    return response;
};

MenuService.attachMultipleItemToMenu = async (data) => {
    const response = await axios({
        url: 'add-multiple-menu-item',
        method: 'post',
        data,
    });
    return response;
};

MenuService.getAllMenuItem = async (data) => {
    const response = await axios({
        url: '/get-menu-item',
        method: 'post',
        data,
    });
    return response;
};

MenuService.addImageMenuLink = async (data) => {
    const response = await axios({
        url: '/add-image-menu-link',
        method: 'post',
        headers: {
            'content-type': 'multipart/form-data',
        },
        data,
    });
    return response;
};

MenuService.createMenuItems = async (menuPayloads) => {
    const createMenuItemsResponse = await AxiosInstance.all(
        menuPayloads.map((data) =>
            axios({
                url: '/create-or-update-menu-item',
                method: 'post',
                headers: {
                    'content-type': 'multipart/form-data',
                },
                data,
            }),
        ),
    );
    return createMenuItemsResponse;
};

MenuService.getTheams = async () => {
    const response = await axios({
        url: '/get-theme',
        method: 'get',
    });
    return response;
};
export default MenuService;
