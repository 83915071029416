const FirebaseConfig = {
    apiKey: 'AIzaSyCIQjCN8-XzdilIOBOAxWZW3yiidPl_10g',
    authDomain: 'tastes-on-way-owner.firebaseapp.com',
    projectId: 'tastes-on-way-owner',
    storageBucket: 'tastes-on-way-owner.appspot.com',
    messagingSenderId: '264186016114',
    appId: '1:264186016114:web:4a6602375b01bc6f216deb',
    measurementId: 'G-XD2Z04BRMZ',
};
export default FirebaseConfig;
