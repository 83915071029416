import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CustomerSupportService from 'services/CustomerSupportService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    addCustomerSupport: '',
    getCustomerSupportTicket: '',
};

export const addCustomerSupport = createAsyncThunk(
    'customer-support-ticket',
    async (data, { rejectWithValue }) => {
        try {
            const response = await CustomerSupportService.addCustomerSupport(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const getCustomerSupportTicket = createAsyncThunk(
    'get-customer-support-ticket',
    async (_, { rejectWithValue }) => {
        try {
            const response = await CustomerSupportService.getCustomerSupportTicket();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const customerSupportSlice = createSlice({
    name: 'customerSupport',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = '/';
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = '';
            state.showMessage = false;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        addCustomerSupportSucess: (state, action) => {
            state.addCustomerSupport = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addCustomerSupport.pending, (state) => {
                state.addCustomerSupport = '';
            })
            .addCase(addCustomerSupport.fulfilled, (state, action) => {
                state.loading = false;
                state.addCustomerSupport = action.payload;
            })
            .addCase(addCustomerSupport.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getCustomerSupportTicket.pending, (state) => {
                state.getCustomerSupportTicket = '';
            })
            .addCase(getCustomerSupportTicket.fulfilled, (state, action) => {
                state.loading = false;
                state.getCustomerSupportTicket = action.payload;
            })
            .addCase(getCustomerSupportTicket.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    showLoading,
    addCustomerSupportSucess,
} = customerSupportSlice.actions;

export default customerSupportSlice.reducer;
