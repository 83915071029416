import axios from 'auth/FetchInterceptor';
// import axios from 'axios';

const AuthService = {};

AuthService.countryCode = async () => {
    const response = await axios({
        url: '/get-country',
        method: 'get',
    });
    return response;
};
AuthService.login = async (data) => {
    const response = await axios({
        url: '/kitchen-owner-login-registration',
        method: 'post',
        data,
    });
    return response;
};

AuthService.register = function (data) {
    return fetch({
        url: '/auth/register',
        method: 'post',
        data: data,
    });
};

AuthService.getUserProfile = async () => {
    const response = await axios({
        url: '/get-kitchen-owner-profile',
        method: 'get',
    });
    return response;
};
AuthService.logout = function () {
    return fetch({
        url: '/auth/logout',
        method: 'post',
    });
};

AuthService.loginInOAuth = function () {
    return fetch({
        url: '/auth/loginInOAuth',
        method: 'post',
    });
};

export default AuthService;
