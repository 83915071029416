import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ReviewService from 'services/ReviewServices';

export const initialState = {
    loading: false,
    message: '',
    showMessage: '',
    getReviewMessage: '',
};
export const getReviewHistory = createAsyncThunk(
    'get-kitchen-owner-review-history',
    async (pageNumber, { rejectWithValue }) => {
        try {
            const response = await ReviewService.reviewHistory(pageNumber);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        getReviews: (state, action) => {
            state.loading = false;
            state.getBankDetails = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReviewHistory.pending, (state) => {
                state.getReviewMessage = '';
            })
            .addCase(getReviewHistory.fulfilled, (state, action) => {
                (state.loading = false), (state.getReviewMessage = action.payload);
            })
            .addCase(getReviewHistory.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const { getReviews } = reviewSlice.actions;
export default reviewSlice.reducer
