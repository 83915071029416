import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/otp`,
        component: React.lazy(() => import('views/auth-views/authentication/otp')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'add-address',
        path: `${AUTH_PREFIX_PATH}/User-Details`,
        component: React.lazy(() => import('views/auth-views/authentication/add-address')),
    },
    {
        key: 'review-page',
        path: `${AUTH_PREFIX_PATH}/Review`,
        component: React.lazy(() => import('views/pre-auth/Review')),
    },
];

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.coupon',
        path: `${APP_PREFIX_PATH}/dashboards/coupon`,
        component: React.lazy(() => import('views/app-views/dashboards/coupon')),
    },
    {
        key: 'dashboard.bank',
        path: `${APP_PREFIX_PATH}/dashboards/bank/*`,
        component: React.lazy(() => import('views/app-views/dashboards/bank')),
    },
    {
        key: 'dashboard.orders',
        path: `${APP_PREFIX_PATH}/dashboards/orders`,
        component: React.lazy(() => import('views/app-views/dashboards/orders')),
    },
    {
        key: 'dashboard.orders.yourOrders',
        path: `${APP_PREFIX_PATH}/dashboards/yourOrders`,
        component: React.lazy(() => import('views/app-views/dashboards/orders/yourOrders')),
    },
    {
        key: 'dashboard.orders.history',
        path: `${APP_PREFIX_PATH}/dashboards/orders-history`,
        component: React.lazy(() => import('views/app-views/dashboards/orders/ordersHistory')),
    },
    {
        key: 'dashboard.menuItems',
        path: `${APP_PREFIX_PATH}/apps/menu/list`,
        component: React.lazy(() => import('views/app-views/dashboards/menu-list/index')),
    },
    {
        key: 'apps.ecommerce.add-product',
        path: `${APP_PREFIX_PATH}/apps/menu/create`,
        component: React.lazy(() => import('views/app-views/dashboards/menu-create/index')),
    },
    {
        key: 'apps.ecommerce.add-product',
        path: `${APP_PREFIX_PATH}/apps/menu/item/list`,
        component: React.lazy(() => import('views/app-views/dashboards/menu-item-list/index')),
    },
    {
        key: 'apps.ecommerce.add-product',
        path: `${APP_PREFIX_PATH}/apps/menu/edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/menu-create/index')),
    },
    {
        key: 'dashboard.bank',
        path: `${APP_PREFIX_PATH}/dashboards/myMenuDesign`,
        component: React.lazy(() => import('views/app-views/dashboards/myMenuDesign')),
    },
    {
        key: 'dashboard.tutorials',
        path: `${APP_PREFIX_PATH}/dashboards/tutorials`,
        component: React.lazy(() => import('views/app-views/dashboards/tutorials')),
    },
    {
        key: 'dashboard.Feedback',
        path: `${APP_PREFIX_PATH}/dashboards/Feedback`,
        component: React.lazy(() => import('views/app-views/dashboards/Feedback')),
    },

    {
        key: 'dashboard.faq',
        path: `${APP_PREFIX_PATH}/dashboards/faq`,
        component: React.lazy(() => import('views/app-views/dashboards/faq')),
    },
    {
        key: 'dashboard.customerSupport',
        path: `${APP_PREFIX_PATH}/dashboards/customerSupport`,
        component: React.lazy(() => import('views/app-views/dashboards/customerSupport')),
    },
    {
        key: 'dashboard.earningSummary',
        path: `${APP_PREFIX_PATH}/dashboards/Earning-Summary`,
        component: React.lazy(() => import('views/app-views/dashboards/earningSummary')),
    },
    {
        key: 'dashboard.Address',
        path: `${APP_PREFIX_PATH}/dashboards/Manage-Address`,
        component: React.lazy(() => import('views/app-views/dashboards/Address')),
    },
    {
        key: 'dashboard.reviewHistory',
        path: `${APP_PREFIX_PATH}/dashboards/reviewHistory`,
        component: React.lazy(() => import('views/app-views/dashboards/reviewHistory')),
    },
    {
        key: 'dashboard.customerSupport.raisTicket',
        path: `${APP_PREFIX_PATH}/dashboards/customerSupport/raiseTicket`,
        component: React.lazy(() =>
            import('views/app-views/dashboards/customerSupport/raiseTicket'),
        ),
    },
    {
        key: 'dashboard.customerSupport.ticketStatus',
        path: `${APP_PREFIX_PATH}/dashboards/customerSupport/ticketStatus`,
        component: React.lazy(() =>
            import('views/app-views/dashboards/customerSupport/ticketStatus'),
        ),
    },
];
 