import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrderSerivce from 'services/OrderService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    getOrderhistory: '',
    getOrderList: '',
    updateOrderStatus: '',
    updateDeliveryPersonStatus: '',
};

export const getOrderhistory = createAsyncThunk(
    'order-history',
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderSerivce.getOrderHstory(data.apiData, data.page);
            localStorage.setItem('orderHistory', JSON.stringify(response));
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const getOrderList = createAsyncThunk(
    'kitchen-owner-order-list',
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderSerivce.getOrderList(data.apiData, data.page);
            localStorage.setItem('orderList', JSON.stringify(response?.data));
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const updateOrderStatus = createAsyncThunk(
    'update-order-status',
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderSerivce.updateOrderStatus(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const updateDeliveryPersonStatus = createAsyncThunk(
    'delivery-person-status-update',
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderSerivce.updateDeliveryPersonStatus(data);

            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const removeMessageOrderStatus = createAsyncThunk(
    'removeMessageOrderStatus',
    async (_, { rejectWithValue }) => {
        try {
            const data = null;
            return data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = '/';
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = '';
            state.showMessage = false;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        getOrderHistorySuccess: (state, action) => {
            state.loading = false;
            state.getOrderhistory = action.payload;
        },
        getOrderListSuccess: (state, action) => {
            state.loading = false;
            state.getOrderList = action.payload;
        },
        updateOrderStatusSuccess: (state, action) => {
            state.loading = false;
            state.getOrderList = action.payload;
        },
        updateDeliveryPersonStatusSuccess: (state, action) => {
            state.loading = false;
            state.updateDeliveryPersonStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrderhistory.pending, (state) => {
                state.loading = true;
                state.getOrderhistory = '';
            })
            .addCase(getOrderhistory.fulfilled, (state, action) => {
                state.loading = false;
                state.getOrderhistory = action.payload;
            })
            .addCase(getOrderhistory.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getOrderList.pending, (state) => {
                state.getOrderList = '';
            })
            .addCase(getOrderList.fulfilled, (state, action) => {
                state.loading = false;
                state.getOrderList = action.payload;
            })
            .addCase(getOrderList.rejected, (state) => {
                state.loading = false;
            })
            .addCase(updateOrderStatus.pending, (state) => {
                state.updateOrderStatus = '';
            })
            .addCase(updateOrderStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.updateOrderStatus = action.payload;
            })
            .addCase(updateOrderStatus.rejected, (state) => {
                state.loading = false;
            })
            .addCase(updateDeliveryPersonStatus.pending, (state) => {
                state.updateDeliveryPersonStatus = '';
            })
            .addCase(updateDeliveryPersonStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.updateDeliveryPersonStatus = action.payload;
            })
            .addCase(updateDeliveryPersonStatus.rejected, (state) => {
                state.loading = false;
            })
            .addCase(removeMessageOrderStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeMessageOrderStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.updateOrderStatus = action.payload;
                state.updateDeliveryPersonStatus = action.payload;
            })
            .addCase(removeMessageOrderStatus.rejected, (state, action) => {
                state.loading = false;
                state.updateOrderStatus = action.payload;
                state.updateDeliveryPersonStatus = action.payload;
            });
    },
});

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    showLoading,
    getOrderHistorySuccess,
    getOrderListSuccess,
    updateOrderStatusSuccess,
    updateDeliveryPersonStatusSuccess,
} = orderSlice.actions;

export default orderSlice.reducer;
