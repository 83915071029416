import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    addUserDetails: '',
    addUserAddress: '',
    addCuisines: '',
    getAddress: '',
    areaData: '',
    getUserDashboard: '',
    getUserEarningSummary: '',
    getUserCoupon: '',
    getKitchenOwnerProfile: '',
    deleteCoupondata: '',
    createCoupon: '',
    updateCoupon: '',
    updateCouponStatus: '',
    userAvailabilityStatus: '',
    getDeviceToken: '',
};

export const addUserDetails = createAsyncThunk(
    'kitchen-owner-update-profile',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.addUserDetails(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const userAvailability = createAsyncThunk(
    'kitchen-owner-update-availability',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.userAvailability(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const addUserAddress = createAsyncThunk(
    'create-or-update-address',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.addUserAddress(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const addCuisines = createAsyncThunk(
    'create-or-update-kitchen-owner-cuisine',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.addCuisines(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const getUserDashboard = createAsyncThunk(
    'kitchen-owner-dashboard',
    async (_, { rejectWithValue }) => {
        try {
            const response = await UserService.getUserDashboard();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const getUserEarningSummary = createAsyncThunk(
    'kitchen-Owner-earning-summary',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.getUserEarningSummary(data.data, data.page);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const requestCall = createAsyncThunk(
    'create-request-callback',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.requestCall(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const getUserCoupon = createAsyncThunk('get-coupons', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.getUserCoupon(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});
export const getKitchenOwnerProfile = createAsyncThunk(
    'get-kitchen-owner-profile',
    async (_, { rejectWithValue }) => {
        try {
            const response = await UserService.getKitchenOwnerProfile();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const deleteCoupon = createAsyncThunk('delete-coupon', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.deleteCoupon(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});
export const createCoupon = createAsyncThunk('create-coupon', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.createCoupon(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});
export const updateCoupon = createAsyncThunk('update-coupon', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.updateCoupon(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});
export const updateCouponStatus = createAsyncThunk(
    'update-coupon-status',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.updateCouponStatus(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const getUserAddress = createAsyncThunk('get-address', async (_, { rejectWithValue }) => {
    try {
        const response = await UserService.getAddress();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const getArea = createAsyncThunk('get-city-area', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.getArea(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});
export const getCities = createAsyncThunk('get-cities', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.getCities(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});
export const getState = createAsyncThunk('get-states', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.getState(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});
export const getDeviceToken = createAsyncThunk(
    'create-or-update-device-token',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.getDeviceToken(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);
export const deleteOwnerReview = createAsyncThunk(
    'delete-kitchen-owner-review',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.deleteOwnerReview(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = '/';
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = '';
            state.showMessage = false;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        addUserDetailsSuccess: (state, action) => {
            state.loading = false;
            state.addUserDetails = action.payload;
        },
        addUserAddressSuccess: (state, action) => {
            state.loading = false;
            state.addUserAddress = action.payload;
        },
        addCuisinesSuccess: (state, action) => {
            state.loading = false;
            state.addCuisines = action.payload;
        },
        getUserDashboardSuccess: (state, action) => {
            state.loading = false;
            state.getUserDashboard = action.payload;
        },
        getUserEarningSummarySuccess: (state, action) => {
            state.loading = false;
            state.getUserDashboard = action.payload;
        },
        requestCallSuccess: (state, action) => {
            state.loading = false;
            state.requestCallSuccess = action.payload;
        },
        getUserCouponSuccess: (state, action) => {
            state.loading = false;
            state.getUserCouponSuccess = action.payload;
        },
        getKitchenOwnerProfileSuccess: (state, action) => {
            state.loading = false;
            state.getKitchenOwnerProfileSuccess = action.payload;
        },
        deleteCouponSuccess: (state, action) => {
            state.loading = false;
            state.deleteCouponSuccess = action.payload;
        },
        createCouponSuccess: (state, action) => {
            state.loading = false;
            state.createCouponSuccess = action.payload;
        },
        updateCouponSuccess: (state, action) => {
            state.loading = false;
            state.updateCouponSuccess = action.payload;
        },
        updateCouponStatusSuccess: (state, action) => {
            state.loading = false;
            state.updateCouponStatusSuccess = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addUserDetails.pending, (state) => {
                state.loading = true;
                state.addUserDetails = '';
            })
            .addCase(addUserDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.addUserDetails = action.payload;
            })
            .addCase(addUserDetails.rejected, (state) => {
                state.loading = false;
            })
            .addCase(addUserAddress.pending, (state) => {
                state.loading = true;
                state.addUserAddress = '';
            })
            .addCase(addUserAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.addUserAddress = action.payload;
            })
            .addCase(addUserAddress.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getUserAddress.pending, (state) => {
                state.loading = true;
                state.getAddress = '';
            })
            .addCase(getUserAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.getAddress = action.payload;
            })
            .addCase(getUserAddress.rejected, (state) => {
                state.loading = false;
            })
            .addCase(addCuisines.pending, (state) => {
                state.loading = true;
                state.addCuisines = '';
            })
            .addCase(addCuisines.fulfilled, (state, action) => {
                state.loading = false;
                state.addCuisines = action.payload;
            })
            .addCase(addCuisines.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getUserDashboard.pending, (state) => {
                state.getUserDashboard = '';
            })
            .addCase(getUserDashboard.fulfilled, (state, action) => {
                state.loading = false;
                state.getUserDashboard = action.payload;
            })
            .addCase(getUserDashboard.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getUserEarningSummary.pending, (state) => {
                state.getUserEarningSummary = '';
            })
            .addCase(getUserEarningSummary.fulfilled, (state, action) => {
                state.loading = false;
                state.getUserEarningSummary = action.payload;
            })
            .addCase(getUserEarningSummary.rejected, (state) => {
                state.loading = false;
            })
            .addCase(requestCall.pending, (state) => {
                state.requestCall = '';
            })
            .addCase(requestCall.fulfilled, (state, action) => {
                state.loading = false;
                state.requestCall = action.payload;
            })
            .addCase(requestCall.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getUserCoupon.pending, (state) => {
                state.getUserCoupon = '';
            })
            .addCase(getUserCoupon.fulfilled, (state, action) => {
                state.loading = false;
                state.getUserCoupon = action.payload;
            })
            .addCase(getUserCoupon.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getKitchenOwnerProfile.pending, (state) => {
                state.getKitchenOwnerProfile = '';
            })
            .addCase(getKitchenOwnerProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.getKitchenOwnerProfile = action.payload;
            })
            .addCase(getKitchenOwnerProfile.rejected, (state) => {
                state.loading = false;
            })
            .addCase(deleteCoupon.pending, (state) => {
                state.deleteCoupondata = '';
            })
            .addCase(deleteCoupon.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteCoupondata = action.payload;
            })
            .addCase(deleteCoupon.rejected, (state) => {
                state.loading = false;
            })
            .addCase(createCoupon.pending, (state) => {
                state.createCoupon = '';
            })
            .addCase(createCoupon.fulfilled, (state, action) => {
                state.loading = false;
                state.createCoupon = action.payload;
            })
            .addCase(createCoupon.rejected, (state) => {
                state.loading = false;
            })
            .addCase(updateCoupon.pending, (state) => {
                state.updateCoupon = '';
            })
            .addCase(updateCoupon.fulfilled, (state, action) => {
                state.loading = false;
                state.updateCoupon = action.payload;
            })
            .addCase(updateCoupon.rejected, (state) => {
                state.loading = false;
            })
            .addCase(updateCouponStatus.pending, (state) => {
                state.updateCouponStatus = '';
            })
            .addCase(updateCouponStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.updateCouponStatus = action.payload;
            })
            .addCase(updateCouponStatus.rejected, (state) => {
                state.loading = false;
            })
            .addCase(userAvailability.pending, (state) => {
                state.userAvailabilityStatus = '';
            })
            .addCase(userAvailability.fulfilled, (state, action) => {
                state.loading = false;
                state.userAvailabilityStatus = action.payload;
            })
            .addCase(userAvailability.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getArea.pending, (state) => {
                state.areaData = '';
            })
            .addCase(getArea.fulfilled, (state, action) => {
                state.loading = false;
                state.areaData = action.payload;
            })
            .addCase(getArea.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getCities.pending, (state) => {
                state.areaData = '';
            })
            .addCase(getCities.fulfilled, (state, action) => {
                state.loading = false;
                state.cityData = action.payload;
            })
            .addCase(getCities.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getState.pending, (state) => {
                state.areaData = '';
            })
            .addCase(getState.fulfilled, (state, action) => {
                state.loading = false;
                state.stateData = action.payload;
            })
            .addCase(getState.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getDeviceToken.pending, (state) => {
                state.getDeviceToken = '';
            })
            .addCase(getDeviceToken.fulfilled, (state, action) => {
                state.loading = false;
                state.getDeviceToken = action.payload;
            })
            .addCase(getDeviceToken.rejected, (state) => {
                state.loading = false;
            })
            .addCase(deleteOwnerReview.pending, (state) => {
                state.deleteOwnerReview = '';
            })
            .addCase(deleteOwnerReview.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteOwnerReview = action.payload;
            })
            .addCase(deleteOwnerReview.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    showLoading,
    addUserDetailsSuccess,
    addUserAddressSuccess,
    addCuisinesSuccess,
    getUserDashboardSuccess,
    getUserEarningSummarySuccess,
    requestCallSuccess,
    getUserCouponSuccess,
    getKitchenOwnerProfileSuccess,
    deleteCouponSuccess,
    createCouponSuccess,
    updateCouponSuccess,
    updateCouponStatusSuccess,
} = userSlice.actions;

export default userSlice.reducer;
