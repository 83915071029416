import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MenuService from 'services/MenuService';

export const initialState = {
    createMenu: {
        success: null,
        failure: null,
        loading: false,
        message: '',
        showMessage: false,
    },
    menuList: {
        data: null,
        failure: null,
        loading: false,
        message: '',
        showMessage: false,
    },
    theme: {
        data: [],
    },
};

export const createMenu = createAsyncThunk('createMenu', async (payload, { rejectWithValue }) => {
    try {
        let response = await MenuService.createMenu(payload);
        response.data['menuName'] = payload.name;
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const getAllMenuItem = createAsyncThunk(
    'getAllMenuItem',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await MenuService.getAllMenuItem(payload);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const deleteMenu = createAsyncThunk('deleteMenu', async (payload, { rejectWithValue }) => {
    try {
        const response = await MenuService.deleteMenu(payload);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});


export const deleteMenuItem = createAsyncThunk('deleteMenuItem', async (payload, { rejectWithValue }) => {
    try {
        const response = await MenuService.deleteMenuItem(payload);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const editMenu = createAsyncThunk('editMenu', async (payload, { rejectWithValue }) => {
    try {
        const response = await MenuService.editMenu(payload);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const getMenuList = createAsyncThunk('getMenuList', async (payload, { rejectWithValue }) => {
    try {
        const response = await MenuService.getMenuList(payload);
        return response?.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const getAllMenuItemList = createAsyncThunk(
    'getAllMenuItemList',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await MenuService.getAllMenuItemList(payload);
            return response?.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const deleteIngredientItem = createAsyncThunk(
    'deleteIngredientItem',
    async (payload, { rejectWithValue }) => {
        try {
            let response = await MenuService.deleteIngredientItem(payload);

            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const getMenuById = createAsyncThunk('getMenuById', async (payload, { rejectWithValue }) => {
    try {
        const response = await MenuService.getMenuById(payload);
        return response?.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const getOwnerMenuByID = createAsyncThunk('getOwnerMenuByID', async (payload, { rejectWithValue }) => {
    try {
        const response = await MenuService.getOwnerMenuByID(payload);
        return response?.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});


export const createMenuItems = createAsyncThunk(
    'createMenuItems',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await MenuService.createMenuItems(payload);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const attachMultipleItemToMenu = createAsyncThunk(
    'attachMultipleItemToMenu',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await MenuService.attachMultipleItemToMenu(payload);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const getTheams = createAsyncThunk('getTheams', async (payload, { rejectWithValue }) => {
    try {
        const response = await MenuService.getTheams();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
    }
});

export const attachImageToMenu = createAsyncThunk(
    'attachImageToMenu',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await MenuService.addImageMenuLink(payload);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    },
);

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        allMenuItems: [],
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllMenuItem.pending, (state) => {
                state.allMenuItems = [];
                state.loading = true;
            })
            .addCase(getAllMenuItem.fulfilled, (state, action) => {
                state.loading = false;
                state.allMenuItems = action.payload?.data;
            })
            .addCase(getAllMenuItem.rejected, (state) => {
                state.loading = false;
            })
            .addCase(createMenuItems.pending, (state) => {
                state.createMenuItems = [];
                state.loading = true;
            })
            .addCase(createMenuItems.fulfilled, (state, action) => {
                state.loading = false;
                state.createMenuItems = action.payload?.data;
            })
            .addCase(createMenuItems.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getTheams.pending, (state) => {
                state.theme = {};
                state.loading = true;
            })
            .addCase(getTheams.fulfilled, (state, action) => {
                state.loading = false;
                state.theme = action.payload?.data;
            })
            .addCase(getTheams.rejected, (state) => {
                state.loading = false;
            })
            .addCase(createMenu.pending, (state) => {
                state.theme = {};
                state.loading = true;
            })
            .addCase(createMenu.fulfilled, (state, action) => {
                state.loading = false;
                state.createOrUpdateMenu = action.payload?.data;
            })
            .addCase(createMenu.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getMenuList.pending, (state) => {
                state.menuList = {};
                state.loading = true;
            })
            .addCase(getMenuList.fulfilled, (state, action) => {
                state.loading = false;
                state.menuList.data = action.payload;
            })
            .addCase(getMenuList.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getMenuById.pending, (state) => {
                state.MenuByID = {};
                state.loading = true;
            })
            .addCase(getMenuById.fulfilled, (state, action) => {
                state.loading = false;
                state.MenuByID = action.payload;
            })
            .addCase(getMenuById.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getAllMenuItemList.pending, (state) => {
                state.itemList = {};
                state.loading = true;
            })
            .addCase(getAllMenuItemList.fulfilled, (state, action) => {
                state.loading = false;
                state.itemList = action.payload;
            })
            .addCase(getAllMenuItemList.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getOwnerMenuByID.pending, (state) => {
                state.itemList = {};
                state.loading = true;
            })
            .addCase(getOwnerMenuByID.fulfilled, (state, action) => {
                state.loading = false;
                state.menuByIDData = action.payload;
            })
            .addCase(getOwnerMenuByID.rejected, (state) => {
                state.loading = false;
            });
    },
});
// eslint-disable-next-line no-empty-pattern
export const {} = menuSlice.actions;

export default menuSlice.reducer;
