import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
// import { signOutSuccess } from 'store/slices/authSlice';
// import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';


// const unauthorizedCode = [400, 401, 403];

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
});
// Config
// const TOKEN_PAYLOAD_KEY = 'authorization';

// API Request interceptor
service.interceptors.request.use(
    (config) => {
        const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

        if (jwtToken) {
            config.headers['Authorization'] = `Bearer ${jwtToken}`;
            config.headers['Accept'] = 'application/json';
            config.headers['Content-Type'] = config.headers['Content-Type']
                ? config.headers['Content-Type']
                : 'application/json';
        }
        return config;
    },
    (error) => {
        // Do something with request error here
        notification.error({
            message: 'Error',
        });
        Promise.reject(error);
    },
);

// API respone interceptor
let isRefreshing = false;
let refreshSubscribers = [];

service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    async (error) => {
        const {
            config,
            response: { status },
        } = error;
        if (status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                const getrefreshToken = JSON.parse(localStorage.getItem('loginOrRegister'));
                const refreshToken = getrefreshToken.loginResponse.data[0].refresh_token;
                const data = {
                    refresh_token: refreshToken,
                };
                try {
                    try {
                        const res = await axios.post(`${API_BASE_URL}refresh-token`, data);
                        localStorage.setItem(AUTH_TOKEN, res.data.data.original.access_token);
                        onRefreshed(res.data.data.original.access_token);
                        return service(config);
                    } catch (err) {
                        localStorage.clear();
                        location.reload();
                        return await Promise.reject(err);
                    }
                } finally {
                    isRefreshing = false;
                    refreshSubscribers = [];
                }
            }
            const requestSubscribers = new Promise((resolve) => {
                refreshSubscribers.push((token) => {
                    config.headers.Authorization = `Bearer ${token}`;
                    resolve(service(config));
                });
            });
            return requestSubscribers;
        }
        function onRefreshed(token) {
            refreshSubscribers.forEach((subscriber) => subscriber(token));
        }

        let notificationParam = {
            message: '',
        };

        // Remove token and redirect
        // if (unauthorizedCode.includes(error.response.status)) {
        //     notificationParam.message = 'Authentication Fail';
        //     notificationParam.description = 'Please login again';
        //     // localStorage.removeItem(AUTH_TOKEN);

        //     store.dispatch(signOutSuccess());
        // }

        if (error.response.status === 400) {
            notificationParam.message = error.response.data.message;
        }
        if (error.response.status === 401) {
            notificationParam.message = error.response.data.message;
        }
        if (error.response.status === 403) {
            notificationParam.message = error.response.data.message;
        }
        if (error.response.status === 404) {
            notificationParam.message = error.response.data.message;
        }
        if (error.response.status === 500) {
            notificationParam.message = error.response.data.message;
        }

        if (error.response.status === 508) {
            notificationParam.message = error.response.data.message;
        }
        if (error.response.status === 422) {
            notificationParam.message = error.response.data.message;
        }

        notification.error(notificationParam);

        return Promise.reject(error);
    },
);

export default service;
