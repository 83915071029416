import axios from 'auth/FetchInterceptor';

const UserService = {};

UserService.addUserDetails = async (data) => {
    const response = await axios({
        url: '/kitchen-owner-update-profile',
        method: 'post',
        data,
    });
    return response;
};
UserService.addUserAddress = async (data) => {
    const response = await axios({
        url: '/create-or-update-address',
        method: 'post',
        data,
    });
    return response;
};
UserService.addCuisines = async (data) => {
    const response = await axios({
        url: '/create-or-update-kitchen-owner-cuisine',
        method: 'post',
        data,
    });
    return response;
};
UserService.getUserDashboard = async () => {
    const response = await axios({
        url: '/kitchen-owner-dashboard',
        method: 'get',
    });
    return response;
};
UserService.getUserEarningSummary = async (data, page) => {
    const response = await axios({
        url: `/kitchen-Owner-earning-summary?page=${page}`,
        method: 'post',
        data,
    });

    return response;
};
UserService.requestCall = async (data) => {
    const response = await axios({
        url: '/create-request-callback',
        method: 'post',
        data,
    });

    return response;
};
UserService.userAvailability = async (data) => {
    const response = await axios({
        url: `/update-kitchen-owner-availability`,
        method: 'post',
        data,
    });

    return response;
};
UserService.getUserCoupon = async (data) => {
    const response = await axios({
        url: `/get-coupons?page=${data?.page ? data.page : 1}`,
        method: 'post',
        data,
    });

    return response;
};
UserService.getKitchenOwnerProfile = async () => {
    const response = await axios({
        url: '/get-kitchen-owner-profile',
        method: 'get',
    });

    return response;
};
UserService.deleteCoupon = async (data) => {
    const response = await axios({
        url: '/delete-coupon',
        method: 'delete',
        data,
    });

    return response;
};
UserService.createCoupon = async (data) => {
    const response = await axios({
        url: '/create-coupon',
        method: 'post',
        data,
    });

    return response;
};
UserService.updateCoupon = async (data) => {
    const response = await axios({
        url: '/update-coupon',
        method: 'patch',
        data,
    });

    return response;
};
UserService.updateCouponStatus = async (data) => {
    const response = await axios({
        url: '/update-coupon-status',
        method: 'post',
        data,
    });

    return response;
};
UserService.getAddress = async () => {
    const response = await axios({
        url: '/get-addresses',
        method: 'get',
    });

    return response;
};
UserService.getArea = async (data) => {
    const response = await axios({
        url: '/get-city-area',
        method: 'post',
        data,
    });

    return response;
};
UserService.getCities = async (data) => {
    const response = await axios({
        url: '/get-cities',
        method: 'post',
        data,
    });

    return response;
};
UserService.getState = async (data) => {
    const response = await axios({
        url: '/get-states',
        method: 'post',
        data,
    });

    return response;
};
UserService.getDeviceToken = async (data) => {
    const response = await axios({
        url: '/create-or-update-device-token',
        method: 'post',
        data,
    });

    return response;
};
UserService.deleteOwnerReview = async (data) => {
    const response = await axios({
        url: '/delete-kitchen-owner-review',
        method: 'delete',
        data,
    });

    return response;
};
export default UserService;
